const data = [
  {
    responsive_id: '',
    id: 95,
    full_name: 'Edwina Ebsworth',
    subject: 'Puzi',
    category: 'Human Resources Assistant',
    phone_number: '+237 6XX XX XX XX',
    status: 1,
    replied: 3,
  },
  {
    responsive_id: '',
    id: 1,
    full_name: "Korrie O'Crevy",
    subject: 'Krasnosilka',
    category: 'Nuclear Power Engineer',
    phone_number: '+237 6XX XX XX XX',
    status: 2,
    replied: 3,
  },
  {
    responsive_id: '',
    id: 7,
    full_name: 'Eileen Diehn',
    subject: 'Lampuyang',
    category: 'Environmental Specialist',
    phone_number: '+237 6XX XX XX XX',
    status: 2,
    replied: 3,
  },
  {
    responsive_id: '',
    id: 11,
    full_name: 'De Falloon',
    subject: 'Sales Representative',
    category: 'Colima',
    phone_number: '+237 6XX XX XX XX',
    status: 2,
    replied: 4,
  },
  {
    responsive_id: '',
    id: 3,
    full_name: 'Stella Ganderton',
    subject: 'Sales Golcowa',
    category: 'Operator',
    phone_number: '+237 6XX XX XX XX',
    status: 2,
    replied: 4,
  },
  {
    responsive_id: '',
    id: 15,
    full_name: 'Harmonia Nisius',
    subject: 'Senior Cost Accountant',
    category: 'Operator Lucan',
    phone_number: '+237 6XX XX XX XX',
    status: 1,
    replied: 3,
  },
  {
    responsive_id: '',
    id: 5,
    full_name: 'Genevra Honeywood',
    subject: 'Senior Cost Geologist',
    category: 'Maofan Lucan',
    phone_number: '+237 6XX XX XX XX',
    status: 1,
    replied: 3,
  },
  {
    responsive_id: '',
    id: 4,
    full_name: 'Dorolice Crossman',
    subject: 'Cost Accountant',
    category: 'Paquera',
    phone_number: '+237 6XX XX XX XX',
    status: 2,
    replied: 4,
  },
]

export default data
